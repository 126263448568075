export const REFERENCE_LIST = [
  '',
  'Others',
  'Mahindra-Karnaphuli',
  'Abid',
  'Enam Mama',
  'Rangs Motors',
  'Rangpur Office',
  'Dhaka Office',
  'TAHIR',
  'ARIF',
  'SONJOY',
  'KAIYUM',
  'SHIPAR',
  'SAIDUR',
  'SHIPON',
];
export const DEVICE_TYPE_LIST = [
  'GT06',
  'WeTrack Two',
  'SinoTrack',
  'S102A',
  'S06A',
  'S5E',
  'R12L',
  'TK309',
  'TK004',
  'QS109',
];
export const VEHICLE_TYPE = [
  { name: 'Car', value: 1 },
  { name: 'Bike', value: 2 },
  { name: 'Micro-Bus', value: 3 },
  { name: 'Bus', value: 4 },
  { name: 'Truck', value: 5 },
  { name: 'CNG', value: 6 },
  { name: 'Ship', value: 7 },
  { name: 'Tractor', value: 8 },
  { name: 'Others', value: 9 },
];

export const MAP_API_KEY = 'AIzaSyDqLAok8VFxErXTvGp_uoWZFRygnOeILoQ';

export const ACTIVE_STATUS_DAY = 7;

export const getActiveCount = (devices) => {
  return devices
    .filter((dev) => dev.geo)
    .filter(
      (dev) =>
        dev.geo.update_time &&
        Math.floor(
          (new Date() - new Date(dev.geo.update_time)) / (1000 * 60 * 60 * 24)
        ) <= ACTIVE_STATUS_DAY
    ).length;
};

export const getInactiveCount = (devices) => {
  return devices
    .filter((dev) => dev.geo)
    .filter(
      (dev) =>
        dev.geo.update_time &&
        Math.floor(
          (new Date() - new Date(dev.geo.update_time)) / (1000 * 60 * 60 * 24)
        ) > ACTIVE_STATUS_DAY
    ).length;
};

export const getRunningCount = (devices) => {
  return devices.filter((dev) => dev.geo && dev.geo.acc === 'ON').length;
};

export const getStoppedCount = (devices) =>
  devices.filter((dev) => dev.geo && dev.geo.acc === 'OFF').length;
