import React, { useEffect, useState } from 'react';

import { withDevice } from '../web/device';
import { grey } from '@material-ui/core/colors';
import MyDatePicker from '../web/component/daily-report/date_picker';
import { RETAIL_COLLECTION_URL } from '../../utils/url';
import { DailyCollectionTable } from '../web/component/virtual_table';
import axios from 'axios';
import {
  Card,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { Search, Clear, GetApp } from '@material-ui/icons';

const monthArr = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const getRegAndPaymentMonth = (purpose) => {
  const arr = purpose.split('(');

  const monthYearArr = arr[1].slice(0, -1).split('/');

  const registration_number = arr[0];
  const month = Number(monthYearArr[0]) - 1;
  const year = Number(monthYearArr[1]);

  //   console.log(registration_number, year, month);
  // console.log(arr);

  return { registration_number, payment_month: monthArr[month] + '-' + year };
};

const mapper = (x) => {
  const { registration_number, payment_month } = getRegAndPaymentMonth(
    x.purpose
  );

  return {
    registration_number,
    payment_month,
    payment_method: x.to.name,
    amount: x.amount,
  };
};

function DailyCollections({ deviceParam }) {
  const [state, setState] = useState({
    data: [],
    date: new Date(),
    search: '',
    tableData: [],
  });
  const { setTitle } = deviceParam;

  useEffect(() => {
    setTitle('Daily Retail Collections');
  }, []);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      let postData = {
        year: state.date.getFullYear(),
        month: state.date.getMonth(),
        day: state.date.getDate(),
      };

      axios
        .post(RETAIL_COLLECTION_URL + 'daily-collections', postData)
        .then((response) => {
          console.log(response.data);

          setState((old) => ({
            ...old,
            data: response.data,
            tableData: response.data.map((x) => mapper(x)),
          }));
        })
        .catch((err) => console.log(err));
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [state.date]);

  const filterFunc = (collection, search) => {
    return (
      collection.registration_number
        .toLowerCase()
        .includes(search.toLowerCase()) ||
      collection.payment_month.toLowerCase().includes(search.toLowerCase()) ||
      collection.payment_method.toLowerCase().includes(search.toLowerCase())
    );
  };

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (state.search === '') {
        setState((old) => ({
          ...old,
          tableData: old.data.map((x) => mapper(x)),
        }));
      } else {
        setState((old) => ({
          ...old,
          tableData: [...old.data]
            .map((x) => mapper(x))
            .filter((x) => filterFunc(x, state.search)),
        }));
      }
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [state.search]);

  const setSelectedDate = (date) => {
    setState((oldState) => ({ ...oldState, date: date }));
  };

  const handleChange = (e) => {
    setState((old) => ({ ...old, search: e.target.value }));
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        width: '100%',
        backgroundColor: grey[400],
      }}
    >
      <div style={{ display: 'flex', flex: 1 }}>
        <Card style={{ width: '100%', height: '100%' }}>
          <div
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                // justifyContent: 'space-between',
                alignItems: 'center',
                gap: 20,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  //   flex: 1,
                  padding: 10,
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ display: 'flex', gap: 20 }}>
                  <div
                    style={{ display: 'flex', flexDirection: 'column', gap: 4 }}
                  >
                    <Typography variant="button">
                      Total Collections
                      <span
                        style={{
                          color: 'rebeccapurple',
                          fontSize: 15,
                          fontStyle: 'oblique',
                          marginLeft: 10,
                        }}
                      >
                        {state.data.reduce((acc, obj) => acc + obj.amount, 0)}{' '}
                        BDT
                      </span>
                    </Typography>
                    <Typography variant="button">
                      Collections Count
                      <span
                        style={{
                          color: 'rebeccapurple',
                          fontSize: 15,
                          fontStyle: 'oblique',
                          marginLeft: 10,
                        }}
                      >
                        {state.data.length} NOS
                      </span>
                    </Typography>
                  </div>

                  <div
                    style={{ display: 'flex', flexDirection: 'column', gap: 4 }}
                  >
                    <Typography variant="button">
                      BKash{' '}
                      <span
                        style={{
                          color: 'rebeccapurple',
                          fontSize: 15,
                          fontStyle: 'oblique',
                          marginLeft: 10,
                        }}
                      >
                        {state.data
                          .filter((x) => x.to.name === 'Bkash')
                          .reduce((acc, obj) => acc + obj.amount, 0)}{' '}
                        BDT
                      </span>
                    </Typography>

                    <Typography variant="button">
                      Nagad{' '}
                      <span
                        style={{
                          color: 'rebeccapurple',
                          fontSize: 15,
                          fontStyle: 'oblique',
                          marginLeft: 10,
                        }}
                      >
                        {state.data
                          .filter((x) => x.to.name === 'Nagad')
                          .reduce((acc, obj) => acc + obj.amount, 0)}{' '}
                        BDT
                      </span>
                    </Typography>

                    {/* <Typography variant="button">
                      Cash{' '}
                      <span
                        style={{
                          color: 'rebeccapurple',
                          fontSize: 15,
                          fontStyle: 'oblique',
                          marginLeft: 10,
                        }}
                      >
                        {state.data
                          .filter((x) => x.to.name === 'Cash')
                          .reduce((acc, obj) => acc + obj.amount, 0)}{' '}
                        BDT
                      </span>
                    </Typography> */}
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 10,
                }}
              >
                <TextField
                  style={{ marginRight: 10 }}
                  placeholder="Search"
                  onChange={handleChange}
                  name="search"
                  value={state.search}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search
                          style={{
                            color: grey[700],
                            padding: 4,
                            cursor: 'pointer',
                          }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setState((old) => ({ ...old, search: '' }))
                          }
                        >
                          <Clear
                            style={{
                              color: grey[700],
                              padding: 4,
                              cursor: 'pointer',
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <MyDatePicker
                  selectedDate={state.date}
                  setSelectedDate={setSelectedDate}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                flex: 1,
              }}
            >
              <DailyCollectionTable data={state.tableData} />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default withDevice(DailyCollections);

// export default DailyCollections;
