import React from 'react';
import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router-dom';
import { amber } from '@material-ui/core/colors';

import {
  Drawer,
  List,
  Avatar,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  Card,
} from '@material-ui/core';

import {
  Group,
  Devices,
  NotificationsActive,
  Reorder,
} from '@material-ui/icons';

const drawerList = [
  { label: 'Users', icon: Group },
  { label: 'Devices', icon: Devices },
  { label: 'Notifications', icon: NotificationsActive },
  { label: 'Requests', icon: Reorder },
  { label: 'Retail Collections', icon: Reorder },
  { label: 'Daily Collections', icon: Reorder },
];

const AdminDrawer = ({
  open,
  setOpen,
  authUser,
  handleDrawerItemClick,
  components,
}) => {
  const selected =
    components[components.length - 1] < 6
      ? components[components.length - 1]
      : 0;
  const handleClick = (index) => {
    handleDrawerItemClick(index);
    setOpen(false);
  };
  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={() => setOpen(false)}
      style={{ maxHeight: '100vh' }}
    >
      <Card
        square
        style={{ width: 300, height: '100vh', backgroundColor: amber[500] }}
      >
        <Box
          display="flex"
          flexDirection="column"
          style={{ width: '100%', height: '100%' }}
        >
          <Box
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
            style={{ paddingTop: 20 }}
          >
            <Avatar
              style={{ width: 80, height: 80 }}
              src={authUser && authUser.image}
              alt="User Photo"
            />

            <Typography style={{ padding: 4 }}>
              {authUser && authUser.name}
            </Typography>
            <Typography variant="caption">
              {authUser && authUser.email}
            </Typography>
          </Box>

          <Box display="flex" flexGrow="1" style={{ padding: 10 }}>
            <List style={{ width: '100%' }}>
              {drawerList.map((item, index) => (
                <ListItem
                  button
                  key={index}
                  divider
                  onClick={() => handleClick(index)}
                  selected={selected === index}
                >
                  <ListItemIcon>{<item.icon color="primary" />}</ListItemIcon>
                  <ListItemText style={{ textTransform: 'uppercase' }}>
                    {item.label}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      </Card>
    </Drawer>
  );
};

export default withFirebase(withRouter(AdminDrawer));
